import { useState } from "react";
import { useAuthContext } from './useAuthContext'

export const useSignup = () => {
    const [errorSignup, setErrorSignup] = useState(null)
    const [isLoadingSignup, setIsLoadingSignup] = useState(null)
    const { dispatch } = useAuthContext()

    const signup = async (firstName, lastName, email, password, confirmPassword, company, vatCode) => {
        setIsLoadingSignup(true)
        setErrorSignup(null)
        
        const response = await fetch('/api/user/signup', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password, confirmPassword, firstName, lastName, company, vatCode })
        })

        const json = await response.json()

        if (!response.ok) {
            setIsLoadingSignup(false)
            setErrorSignup(json.error)
        }
        if (response.ok) {
            localStorage.setItem('user', JSON.stringify(json))
            dispatch({ type: 'LOGIN', payload: json })
            setIsLoadingSignup(false)
        }
    }

    return { signup, isLoadingSignup, errorSignup }
}