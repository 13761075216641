import { Link } from "react-router-dom"
import { useLogout } from "../hooks/useLogout"
import { useAuthContext } from "../hooks/useAuthContext"
import logo from '../assets/img/suite23.png'
import AreaPersonaleIcon from "./svg/AreaPersonaleIcon"

const Navbar = () => {
    const { logout } = useLogout()
    const { user } = useAuthContext()

    const handleClick = () => {
        logout()
    }

    return (
        <header>
            <div className="container">
                <div className='logo'>
                    <Link to="/">
                        <img src={logo} alt='suite23' />
                    </Link>
                </div>
                { <nav>
                    {/* {user && (
                        <div>
                            <span>{user.email}</span>
                            <button onClick={handleClick}>Log out</button>

                            <Link to="/account">Area personale</Link>
                        </div>
                    )} */}
                    {(
                        <Link className="area-personale" to="/account">
                            <AreaPersonaleIcon />
                            <p>Private area</p>
                        </Link>
                    )}
                </nav>}
            </div>
        </header>
    )
}

export default Navbar