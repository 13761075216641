import formatDisanceToNow from 'date-fns/formatDistanceToNow'
import { useAuthContext } from '../hooks/useAuthContext'
import moment from 'moment'

const switchSoundness = {

    engagement: [
        '100%',
        '>80<99%',
        '>50<79%',
        '>30<49%',
        '<29%',
        'negligible',
    ],
    marketExistence: [
        'yes, global',
        'yes, continental',
        'yes, national',
        'yes but in globally dispersed niches',
        'no, but express market need',
        'no, but latent market need',
    ],
    entryBarriers: [
        'The product will be a barries to others',
        'no',
        'yes with cheap and fast solutions',
        'yes with "expensive and fast" or "cheap and slow" solutions',
        'yes with long and expensive solutions ',
        'yes unsolvable',
    ],
    risk: [
        'a,aa,aaa',
        'bb, bbb',
        'ccc, b',
        'c, cc',
        'd',
        'not available/not assessable/not applicable',
    ],
    competitors: [
        'there is no',
        'analogous (solves the same need)',
        'similar (same need in the same way)',
        'identical but more expensive',
        'identical but less expensive',
        'multiple products simultaneously on multiple aspects',
    ],
    trl: [
        '9',
        '7,8',
        '5,6',
        '3,4',
        '1,2',
        'unassesable',
    ],
    innovativeRisk: [
        'none',
        'economic (financing)',
        'market (proof of value)',
        'performance (proof of performance)',
        'technological (proof of concept)',
        'scientific (proof of principle)',
    ],
    esg: [
        'determinants',
        'relevant',
        'limited',
        'negligible',
        'conceivable in the future',
        'none or elements against sustainability',
    ],
    ipOwnership: [
        '100% owned or third party exclusive license',
        'co-ownership',
        'third party owned under non-exclusive license',
        'doesn\'t exist yet or FTO or know how',
        'of third party not available',
        'does not exist but not feasible',
    ],
    internationalCoverage: [
        '>500',
        '>301<499',
        '>101<300',
        '>51<100',
        '<50',
        'only image or prior art',
    ],
    consistencyOfAssumptions: [
        'based on reliable data and overall consistency',
        'based on statistical trends and overall consistency',
        'based on projections and overall consistency',
        'based on unprovable inferences and no consistency',
        'reconstructed from unrelated data and no consistency',
        'completely made-up assumptions and no consistency',
    ]
}

const SurveyDetails = ({ survey }) => {
    const { user } = useAuthContext()

    const toText = (text) => {
        const result = text.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    const mapValues = (value) => {
        switch (value) {
            case 'very_high': return "Very high"
            case 'high': return "High"
            case 'medium': return "Medium"
            case 'low': return "Low"
            case 'very_low': return "Very low"
            case 'absent': return "Absent"
            default: return value
        }
    }

    const mapSoundness = (context, value) => {
        if (switchSoundness[context]) {
            switch (value) {
                case 'very_high': return switchSoundness[context][0]
                case 'high': return switchSoundness[context][1]
                case 'medium': return switchSoundness[context][2]
                case 'low': return switchSoundness[context][3]
                case 'very_low': return switchSoundness[context][4]
                case 'absent': return switchSoundness[context][5]
                default: return value
            }
        }
    }

    console.log(survey)

    return (
        <>
            <div className='survey-details__scores'>
                <div className='survey-title_date'>
                    <h4 className="bold text--L ">{survey.name ? survey.name : 'Unnamed'}</h4>
                    <p><span className="text--S bold">Date:</span><span className='text--S'>{moment(survey.createdAt).format('DD-MM-YYYY HH:mm')}</span></p>
                </div>
                <div className='survey-scores'>
                    <div className='rating-element'>
                        <h3 className='text--M'>Score</h3>
                        <p className='text--L rating-box'>{survey.results.score}</p>
                    </div>
                    <div className='rating-element'>
                        <h3 className='text--M'>Grade</h3>
                        <p className='text--L rating-box'>{survey.results.grade}</p>
                    </div>
                    <div className='rating-element' >
                        <h3 className='text--M'>Wow rating</h3>
                        <p className='text--L rating-box'>{survey.results.wowRating}</p>
                    </div>
                </div>
            </div>
            <div className='survey-details__values'>
                <div className='survey-details business-elegibility'>
                    <h3 className='text--XL'>business elegibility</h3>
                    <div className='values-list'>
                        <p><span className='bold text--S value-list__key'>Criteria</span><span className='bold text--S value-list__rating'>Rating</span></p>
                        {
                            survey.businessElegibility.map(item => <p key={item.name}><span className='bold text--S value-list__key'>{toText(item.name)}</span><span className='text--XS value-list__value'>{mapValues(item.value)}</span></p>)
                        }
                    </div>
                </div>
                <div className='survey-details business-maturity'>
                    <h3 className='text--XL'>business maturity</h3>
                    <div className='values-list'>
                        <p><span className='bold text--S value-list__key'>Criteria</span><span className='bold text--S value-list__rating'>Rating</span></p>
                        {
                            survey.businessMaturity.map(item => <p key={item.name}><span className='bold text--S value-list__key'>{toText(item.name)}</span><span className='text--XS value-list__value'>{mapValues(item.value)}</span></p>)
                        }
                    </div>
                </div>
                <div className='survey-details business-assessment'>
                    <h3 className='text--XL'>business assessment</h3>
                    <div className='values-list'>
                        <p><span className='bold text--S value-list__key'>Criteria</span><span className='bold text--S value-list__rating'>Existance</span><span className='bold text--S value-list__rating'>Soundness</span></p>
                        {
                            survey.businessAssessment.map(item => <p key={item.name}><span className='bold text--S value-list__key'>{toText(item.name)}</span><span className='text--XS value-list__value'>{mapValues(item.value.reliability)}</span><span className='text--XS value-list__value'>{mapSoundness(item.name, item.value.soundness)}</span></p>)
                        }
                    </div>
                </div>
            </div>
        </ >

    )
}

export default SurveyDetails