import { Link } from "react-router-dom"
import { useLogout } from "../hooks/useLogout"
import { useAuthContext } from "../hooks/useAuthContext"
import logo from '../assets/img/suite23.png'
import LogoutIcon from "./svg/LogoutIcon"

const Navbar = () => {
    const { logout } = useLogout()
    const { user } = useAuthContext()

    const handleClick = () => {
        logout()
    }

    return (
        <header className="admin">
            <div className="container-admin">
                <div className='logo-admin'>
                    <Link to="/">
                        <img src={logo} alt='suite23' />
                    </Link>
                </div>
                {<nav className="logout">
                    {user && (
                        <>
                            <p className="bold">{user.email}</p>
                            <button onClick={handleClick}><LogoutIcon /><span>Logout</span></button>
                        </>
                    )}
                </nav>}
            </div>
        </header>
    )
}

export default Navbar