import { useState, useEffect, useLayoutEffect } from "react"
import { useAuthContext } from '../hooks/useAuthContext';


const SearchBar = (props) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    const {user} = useAuthContext()

    //     set search query to empty string
    const [q, setQ] = useState("");
    //     set search parameters
    //     we only what to search countries by capital and name
    //     this list can be longer if you want
    //     you can search countries even by their population
    // just add it to this array
    const [searchParam] = useState(["name"]);

    useEffect(() => {
        fetch("/api/surveys/?name=" + q, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },

                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );
    }, [q]);

    useEffect(() => {
        props.onSuccess(items)
    }, [items])

    function search(items) {
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                );
            });
        });
    }

    if (error) {
        return (
            <div className="text--alert error">Ops, qualcosa è andato storto</div>
        );
    } else if (!isLoaded) {
        return <>Loading...</>;
    } else {
        return (
            <div className="wrapper">
                <div className="search-wrapper">
                    <label htmlFor="search-form">
                        <input
                            type="search"
                            name="search-form"
                            id="search-form"
                            className="search-input"
                            placeholder="Search assessment by name"
                            value={q}
                            /* 
                            // set the value of our useState e
                            //  anytime the user types in the search box
                            */
                            onChange={(e) => setQ(e.target.value)}
                        />
                    </label>
                </div>
            </div>
        );
    }
}

export default SearchBar