import { useEffect, useState } from 'react'
import Dropdown from './Dropdown'
import MultipleDropdown from './MultipeDropdown'
import { Checkbox, formLabelClasses } from '@mui/material'
import { useAuthContext } from "../hooks/useAuthContext"
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import UserPopup from './UserPopup'



import redChip from '../assets/img/red-chip.png'
import greyChip from '../assets/img/grey-chip.png'
import warning from '../assets/svg/warning-triangle-outline.svg'
import { Link } from 'react-router-dom'

//import { useAuthContext } from '../hooks/useAuthContext'

const SurveyForm = () => {


  const classificationOfInventionOptions = [
    { label: '', value: '' },
    { label: 'Product / Service', value: 'product_service' },
    { label: 'Process', value: 'process' },
    { label: 'Business model', value: 'business_model' },
    { label: 'Positioning / Repositioning', value: 'positioning_repositioning' },
    { label: 'Organizational', value: 'organizational' },
    { label: 'Image', value: 'image' }
  ]

  const advancedDropdownOptions = [
    { label: 'Very high', value: 'very_high' },
    { label: 'High', value: 'high' },
    { label: 'Medium', value: 'medium' },
    { label: 'Low', value: 'low' },
    { label: 'Very low', value: 'very_low' },
    { label: 'Absent', value: 'absent' },
  ]

  const engagementSoundnessDropdownOptions = [
    { label: '100%', value: 'very_high' },
    { label: '>80<99%', value: 'high' },
    { label: '>50<79%', value: 'medium' },
    { label: '>30<49%', value: 'low' },
    { label: '<29%', value: 'very_low' },
    { label: 'negligible', value: 'absent' },
  ]

  const marketExistenceSoundnessDropdownOptions = [
    { label: 'yes, global', value: 'very_high' },
    { label: 'yes, continental', value: 'high' },
    { label: 'yes, national', value: 'medium' },
    { label: 'yes but in globally dispersed niches', value: 'low' },
    { label: 'no, but express market need', value: 'very_low' },
    { label: 'no, but latent market need', value: 'absent' },
  ]

  const entryBarriersSoundnessDropdownOptions = [
    { label: 'The product will be a barries to others', value: 'very_high' },
    { label: 'no', value: 'high' },
    { label: 'yes with cheap and fast solutions', value: 'medium' },
    { label: 'yes with "expensive and fast" or "cheap and slow" solutions', value: 'low' },
    { label: 'yes with long and expensive solutions ', value: 'very_low' },
    { label: 'yes unsolvable', value: 'absent' },
  ]

  const riskSoundnessDropdownOptions = [
    { label: 'a,aa,aaa', value: 'very_high' },
    { label: 'bb, bbb', value: 'high' },
    { label: 'ccc, b', value: 'medium' },
    { label: 'c, cc', value: 'low' },
    { label: 'd', value: 'very_low' },
    { label: 'not available/not assessable/not applicable', value: 'absent' },    
  ]

  const competitorsSoundnessDropdownOptions = [
    { label: 'there is no', value: 'very_high' },
    { label: 'analogous (solves the same need)', value: 'high' },
    { label: 'similar (same need in the same way)', value: 'medium' },
    { label: 'identical but more expensive', value: 'low' },
    { label: 'identical but less expensive', value: 'very_low' },
    { label: 'multiple products simultaneously on multiple aspects', value: 'absent' },
  ]

  const tlrSoundnessDropdownOptions = [
    { label: '9', value: 'very_high' },
    { label: '7,8', value: 'high' },
    { label: '5,6', value: 'medium' },
    { label: '3,4', value: 'low' },
    { label: '1,2', value: 'very_low' },
    { label: 'unassesable', value: 'absent' },
  ]

  const innovativeRiskSoundnessDropdownOptions = [
    { label: 'none', value: 'very_high' },
    { label: 'economic (financing)', value: 'high' },
    { label: 'market (proof of value)', value: 'medium' },
    { label: 'performance (proof of performance)', value: 'low' },
    { label: 'technological (proof of concept)', value: 'very_low' },
    { label: 'scientific (proof of principle)', value: 'absent' },
  ]

  const esgSoundnessDropdownOptions = [
    { label: 'determinants', value: 'very_high' },
    { label: 'relevant', value: 'high' },
    { label: 'limited', value: 'medium' },
    { label: 'negligible', value: 'low' },
    { label: 'conceivable in the future', value: 'very_low' },
    { label: 'none or elements against sustainability', value: 'absent' },
  ]

  const ipOwnershipSoundnessDropdownOptions = [
    { label: '100% owned or third party exclusive license', value: 'very_high' },
    { label: 'co-ownership', value: 'high' },
    { label: 'third party owned under non-exclusive license', value: 'medium' },
    { label: 'doesn\'t exist yet or FTO or know how', value: 'low' },
    { label: 'of third party not available', value: 'very_low' },
    { label: 'does not exist but not feasible', value: 'absent' },
  ]

  const internationalCoverageSoundnessDropdownOptions = [
    { label: '>500', value: 'very_high' },
    { label: '>301<499', value: 'high' },
    { label: '>101<300', value: 'medium' },
    { label: '>51<100', value: 'low' },
    { label: '<50', value: 'very_low' },
    { label: 'only image or prior art', value: 'absent' },
  ]

  const consistencyOfAssumptionsSoundnessDropdownOptions = [
    { label: 'based on reliable data and overall consistency', value: 'very_high' },
    { label: 'based on statistical trends and overall consistency', value: 'high' },
    { label: 'based on projections and overall consistency', value: 'medium' },
    { label: 'based on unprovable inferences and no consistency', value: 'low' },
    { label: 'reconstructed from unrelated data and no consistency', value: 'very_low' },
    { label: 'completely made-up assumptions and no consistency', value: 'absent' },
  ]

  const numberDropdown = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
  ]

  const generalDropdownOptions = [
    { label: 'High', value: 'high' },
    { label: 'Low', value: 'low' }
  ]

  const { user } = useAuthContext()

  const [surveyId, setSurveyId] = useState('')

  //type selection
  const [projectType, setProjectType] = useState('business')
  const [classificationOfInvention, setClassificationOfInvention] = useState('')
  const [disabledBusinessElegibility, setDisabledBusinessElegibility] = useState('disabled')

  //business elegibility
  const [institutionalSupport, setInstitutionalSupport] = useState('high')
  const [planning, setPlanning] = useState('high')
  const [preliminaryStudies, setPreliminaryStudies] = useState('high')
  const [knowledgeOfTheRisk, setKnowledgeOfTheRisk] = useState('high')
  const [regionalDimension, setRegionalDimension] = useState('high')
  const [disabledBusinessMaturity, setDisabledBusinessMaturity] = useState('disabled')

  //business maturity
  const [team, setTeam] = useState('high')
  const [market, setMarket] = useState('high')
  const [competitiveLandscape, setCompetitiveLandscape] = useState('high')
  const [esgIssues, setEsgIssues] = useState('high')
  const [intellectualProperty, setIntellectualProperty] = useState('high')
  const [disabledBusinessAssessment, setDisabledBusinessAssessment] = useState('disabled')

  //business assessment
  const [engagementExistance, setEngagementExistance] = useState(true)
  const [engagementReliability, setEngagementReliability] = useState('very_high')
  const [engagementSoundness, setEngagementSoundness] = useState('very_high')
  const [marketExistenceExistance, setMarketExistenceExistance] = useState(true)
  const [marketExistenceReliability, setMarketExistenceReliability] = useState('very_high')
  const [marketExistenceSoundess, setMarketExistenceSoundness] = useState('very_high')
  const [entryBarriersExistance, setEntryBarriersExistance] = useState(true)
  const [entryBarriersReliability, setEntryBarriersReliability] = useState('very_high')
  const [entryBarriersSoundness, setEntryBarriersSoundness] = useState('very_high')
  const [riskExistance, setRiskExistance] = useState(true)
  const [riskReliability, setRiskReliability] = useState('very_high')
  const [riskSoundness, setRiskSoundness] = useState('very_high')
  const [competitorsExistance, setCompetitorsExistance] = useState(true)
  const [competitorsReliability, setCompetitorsReliability] = useState('very_high')
  const [competitorsSoundness, setCompetitorsSoundness] = useState('very_high')
  const [trlExistance, setTrlExistance] = useState(true)
  const [trlReliability, setTrlReliability] = useState('very_high')
  const [trlSoundness, setTrlSoundness] = useState('very_high')
  const [innovativeRiskExistance, setInnovativeRiskExistance] = useState(true)
  const [innovativeRiskReliability, setInnovativeRiskReliability] = useState('very_high')
  const [innovativeRiskSoundness, setInnovativeRiskSoundness] = useState('very_high')
  const [esgExistance, setEsgExistance] = useState(true)
  const [esgReliability, setEsgReliability] = useState('very_high')
  const [esgSoundness, setEsgSoundness] = useState('very_high')
  const [ipOwnershipExistance, setIpOwnershipExistance] = useState(true)
  const [ipOwnershipReliability, setIpOwnershipReliability] = useState('very_high')
  const [ipOwnershipSoundness, setIpOwnershipSoundness] = useState('very_high')
  const [internationalCoverageExistance, setInternationalCoverageExistance] = useState(true)
  const [internationalCoverageReliability, setInternationalCoverageReliability] = useState('very_high')
  const [internationalCoverageSoundness, setInternationalCoverageSoundness] = useState('very_high')
  const [consistencyOfAssumptionsExistance, setConsistencyOfAssumptionsExistance] = useState(true)
  const [consistencyOfAssumptionsReliability, setConsistencyOfAssumptionsReliability] = useState('very_high')
  const [consistencyOfAssumptionsSoundness, setConsistencyOfAssumptionsSoundness] = useState('very_high')

  //wow rating
  const [wowRating, setWowRating] = useState(1)
  const [disableWowRating, setDisableWowRating] = useState('disabled')

  //results 
  const [score, setScore] = useState('-')
  const [grade, setGrade] = useState('-')
  const [wowRate, setWowRate] = useState('-')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  // login
  const [open, setOpen] = useState(false);
  const [loggedInBeforeSubmit, setLoggedInBeforeSubmit] = useState(false)
  const [blurredArea, setBlurredArea] = useState(false)

  const closeModal = () => {
    setOpen(false)
  }

  const closeModalAndSubmit = () => {
    setOpen(false)
    setLoggedInBeforeSubmit(true)
    setBlurredArea(false)
  };

  useEffect(() => {
    loggedInBeforeSubmit ? handleSubmit() : console.log("qui")
  }, [loggedInBeforeSubmit, user])

  const [errorForm, setErrorForm] = useState(null)
  const [success, setSuccess] = useState(null)

  const handleClassificationChange = (e) => {
    e.preventDefault()
    setClassificationOfInvention(e.target.value)
    if (e.target.value !== '') {
      setDisabledBusinessElegibility('')
      document.getElementById('business-elegibility').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    } else {
      setDisabledBusinessElegibility('disabled')
    }
  }

  const enableMaturity = (e) => {
    e.preventDefault()
    setDisabledBusinessMaturity('')
    document.getElementById('business-maturity').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })

  }

  const enableAssessment = (e) => {
    e.preventDefault()
    setDisabledBusinessAssessment('')
    document.getElementById('business-assessment').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  }

  const enableWow = (e) => {
    e.preventDefault()
    setDisableWowRating('')
    document.getElementById('wow-rating').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })

  }

  const changeScores = (json) => {
    setScore(json.results.score)
    setGrade(json.results.grade)
    setWowRate(json.results.wowRating)
  }

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault()
    }
    console.log("entrato in handlesubmit")
    if (!user) {
      console.log("non sono loggato")
      setBlurredArea(true)
      setOpen(true)
      return
    }
    const survey = {
      email: user.email ? user.email : 'guest user',
      context: [
        {
          name: 'projectType',
          value: projectType
        },
        {
          name: 'classificationOfInvention',
          value: classificationOfInvention
        }
      ],
      businessElegibility: [
        {
          name: 'institutionalSupport',
          value: planning
        },
        {
          name: 'planning',
          value: planning
        },
        {
          name: 'preliminaryStudies',
          value: planning
        },
        {
          name: 'knowledgeOfTheRisk',
          value: knowledgeOfTheRisk
        },
        {
          name: 'regionalDimension',
          value: regionalDimension
        }
      ],
      businessMaturity: [
        {
          name: 'team',
          value: team
        },
        {
          name: 'market',
          value: market
        },
        {
          name: 'competitiveLandscape',
          value: competitiveLandscape
        },
        {
          name: 'esgIssues',
          value: esgIssues
        },
        {
          name: 'intellectualProperty',
          value: intellectualProperty
        }
      ],
      businessAssessment: [
        {
          name: 'engagement',
          value: {
            existance: engagementExistance,
            reliability: engagementReliability,
            soundness: engagementSoundness
          }
        },
        {
          name: 'marketExistence',
          value: {
            existance: marketExistenceExistance,
            reliability: marketExistenceReliability,
            soundness: marketExistenceSoundess
          }
        },
        {
          name: 'entryBarriers',
          value: {
            existance: entryBarriersExistance,
            reliability: entryBarriersReliability,
            soundness: entryBarriersSoundness
          }
        },
        {
          name: 'risk',
          value: {
            existance: riskExistance,
            reliability: riskReliability,
            soundness: riskReliability
          }
        },
        {
          name: 'competitors',
          value: {
            existance: competitorsExistance,
            reliability: competitorsReliability,
            soundness: competitorsSoundness
          }
        },
        {
          name: 'trl',
          value: {
            existance: trlExistance,
            reliability: trlReliability,
            soundness: trlSoundness
          }
        },
        {
          name: 'innovativeRisk',
          value: {
            existance: innovativeRiskExistance,
            reliability: innovativeRiskReliability,
            soundness: innovativeRiskSoundness
          }
        },
        {
          name: 'esg',
          value: {
            existance: esgExistance,
            reliability: esgReliability,
            soundness: esgSoundness
          }
        },
        {
          name: 'ipOwnership',
          value: {
            existance: ipOwnershipExistance,
            reliability: ipOwnershipReliability,
            soundness: ipOwnershipSoundness
          }
        },
        {
          name: 'internationalCoverage',
          value: {
            existance: internationalCoverageExistance,
            reliability: internationalCoverageReliability,
            soundness: internationalCoverageSoundness
          }
        },
        {
          name: 'consistencyOfAssumptions',
          value: {
            existance: consistencyOfAssumptionsExistance,
            reliability: consistencyOfAssumptionsReliability,
            soundness: consistencyOfAssumptionsSoundness
          }
        }
      ],
      wowRating: [
        {
          name: 'wowRating',
          value: wowRating
        }
      ],
      name: 'unnamed'
    }

    fetch('/api/surveys', {
      method: 'POST',
      body: JSON.stringify(survey),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user ? user.token : ''}`
      }
    })
      .then(res => {
        if (res.ok) {
          return res.json()
        }
        setErrorForm('Ops, qualcosa è andato storto')
      })
      .then(json => {
        changeScores(json)
        setSurveyId(json._id)
        setEmail(json.email)
        document.getElementById('results').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
      })
      .catch((errorForm) => {
        console.log(errorForm)
      })

    return
  }

  const updateSurvey = (e, json) => {
    e.preventDefault()
    fetch(`/api/surveys/${surveyId}`, {
      method: 'PATCH',
      body: JSON.stringify({
        name: json.name,
        email: json.email
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user ? user.token : ''}`
      }
    })
      .then(res => {
        if (res.ok) {
          setSuccess(true)
          return res.json()
        }
        setErrorForm('Ops, qualcosa è andato storto')
      })
      .catch((errorForm) => {
        console.log(errorForm)
      })
  }

  return (
    <>
      <div className='context'>
        <form className="form form-1 type-selection">
          <h3 className='text--L'>Project type selection</h3>
          <div className='type-selection__radio'>
            <label>
              <p className='text--M'>Business</p>
              <input
                type="radio"
                value="business"
                name="project-type"
                checked={projectType === 'business'}
                onChange={(e) => { setProjectType(e.target.value) }}
              />
              <img src={redChip} id='red-chip' alt="Option 1" />
            </label>
            <label>
              <input
                type="radio"
                value="institutional"
                name="project-type"
                checked={projectType === 'institutional'}
                onChange={(e) => { setProjectType(e.target.value) }}
              />
              <img src={greyChip} id='grey-chip' alt="Option 1" />
              <p className='text--M'>Institutional</p>
            </label>
          </div>
        </form>
        <form className='classification-of-invention'>
          <h3 className='text--S'>Classification of Invention</h3>
          <Dropdown
            options={classificationOfInventionOptions}
            value={classificationOfInvention}
            onChange={handleClassificationChange}
          />
          {classificationOfInvention == '' ? <div className='required-field'>
            <img src={warning} />
            <p className='text--alert'>Required field</p>
          </div> : <></>}
        </form>
      </div>
      <div className='multiple-forms'>
        <div className='forms-top'>
          <div className='survey-form business-elegibility'>
            <form onSubmit={enableMaturity}>
              <fieldset id='business-elegibility' disabled={disabledBusinessElegibility}>
                <h3 className='text--XL'>business elegibility</h3>

                <div className='fieldset-dropdowns'>
                  <div className='text--S'>
                    <p><b>Criteria</b></p>
                    <p><b>Ratings</b></p>
                  </div>
                  <Dropdown
                    label='Institutional support'
                    options={generalDropdownOptions}
                    value={institutionalSupport}
                    onChange={(e) => setInstitutionalSupport(e.target.value)}
                  />
                  <Dropdown
                    label='Planning'
                    options={generalDropdownOptions}
                    value={planning}
                    onChange={(e) => setPlanning(e.target.value)}
                  />
                  <Dropdown
                    label='Preliminary studies'
                    options={generalDropdownOptions}
                    value={preliminaryStudies}
                    onChange={(e) => setPreliminaryStudies(e.target.value)}
                  />
                  <Dropdown
                    label='Knowledge of the risks'
                    options={generalDropdownOptions}
                    value={knowledgeOfTheRisk}
                    onChange={(e) => setKnowledgeOfTheRisk(e.target.value)}
                  />
                  <Dropdown
                    label='Regional dimension'
                    options={generalDropdownOptions}
                    value={regionalDimension}
                    onChange={(e) => setRegionalDimension(e.target.value)}
                  />
                </div>
                <div className='button-area'>
                  <button className='text--button button-primary'>Continue</button>
                </div>
              </fieldset>
            </form>
          </div>
          <div className='survey-form business-maturity'>
            <form onSubmit={enableAssessment}>
              <fieldset id='business-maturity' disabled={disabledBusinessMaturity}>
                <h3 className='text--XL'>business maturity</h3>

                <div className='fieldset-dropdowns'>
                  <div className='text--S'>
                    <p><b>Criteria</b></p>
                    <p><b>Ratings</b></p>
                  </div>
                  <Dropdown
                    label='Team'
                    options={generalDropdownOptions}
                    value={team}
                    onChange={(e) => setTeam(e.target.value)}
                  />
                  <Dropdown
                    label='Market'
                    options={generalDropdownOptions}
                    value={market}
                    onChange={(e) => setMarket(e.target.value)}
                  />
                  <Dropdown
                    label='Product / Service and competitive landscape'
                    options={generalDropdownOptions}
                    value={competitiveLandscape}
                    onChange={(e) => setCompetitiveLandscape(e.target.value)}
                  />
                  <Dropdown
                    label='ESG issues'
                    options={generalDropdownOptions}
                    value={esgIssues}
                    onChange={(e) => setEsgIssues(e.target.value)}
                  />
                  <Dropdown
                    label='Intellectual property'
                    options={generalDropdownOptions}
                    value={intellectualProperty}
                    onChange={(e) => setIntellectualProperty(e.target.value)}
                  />
                </div>
                <div className='button-area'>
                  <button className='text--button button-primary'>Continue</button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        <div className='survey-form business-assessment'>
          <form onSubmit={enableWow}>
            <fieldset id='business-assessment' disabled={disabledBusinessAssessment}>
              <h3 className='text--XL'>business assessment</h3>
              <div className='fieldset-dropdowns'>
                <div className='dropdown multiple-texts text--S'>
                  <p><b>subcriteria</b></p>
                  <p className='short'><b>existent</b></p>
                  <p><b>soundness</b></p>
                  <p><b>reliability</b></p>
                </div>
                <div className='dropdown'>
                  <p className='text--XS'>Engagement</p>
                  <Checkbox
                    checked
                    className="short"
                    disabled={engagementExistance}
                    onChange={(e) => setEngagementExistance(e.target.checked)}
                  />
                  <MultipleDropdown
                    options={advancedDropdownOptions}
                    value={engagementReliability}
                    onChange={(e) => setEngagementReliability(e.target.value)}
                  />
                  <MultipleDropdown
                    options={engagementSoundnessDropdownOptions}
                    value={engagementSoundness}
                    onChange={(e) => setEngagementSoundness(e.target.value)}
                  />
                </div>
                <div className='dropdown'>
                  <p className='text--XS'>Market existence</p>
                  <Checkbox
                    checked
                    className="short"
                    disabled={marketExistenceExistance}
                    onChange={(e) => setMarketExistenceExistance(e.target.checked)}
                  />
                  <MultipleDropdown
                    options={advancedDropdownOptions}
                    value={marketExistenceReliability}
                    onChange={(e) => setMarketExistenceReliability(e.target.value)}
                  />
                  <MultipleDropdown
                    options={marketExistenceSoundnessDropdownOptions}
                    value={marketExistenceSoundess}
                    onChange={(e) => setMarketExistenceSoundness(e.target.value)}
                  />
                </div>
                <div className='dropdown'>
                  <p className='text--XS'>Entry barriers</p>
                  <Checkbox
                    checked
                    className="short"
                    disabled={entryBarriersExistance}
                    onChange={(e) => setEntryBarriersExistance(e.target.checked)}
                  />
                  <MultipleDropdown
                    options={advancedDropdownOptions}
                    value={entryBarriersReliability}
                    onChange={(e) => setEntryBarriersReliability(e.target.value)}
                  />
                  <MultipleDropdown
                    options={entryBarriersSoundnessDropdownOptions}
                    value={entryBarriersSoundness}
                    onChange={(e) => setEntryBarriersSoundness(e.target.value)}
                  />
                </div>
                <div className='dropdown'>
                  <p className='text--XS'>Country / Regional risk</p>
                  <Checkbox
                    checked
                    className="short"
                    disabled={riskExistance}
                    onChange={(e) => setRiskExistance(e.target.checked)}
                  />
                  <MultipleDropdown
                    options={advancedDropdownOptions}
                    value={riskReliability}
                    onChange={(e) => setRiskReliability(e.target.value)}
                  />
                  <MultipleDropdown
                    options={riskSoundnessDropdownOptions}
                    value={riskSoundness}
                    onChange={(e) => setRiskSoundness(e.target.value)}
                  />
                </div>
                <div className='dropdown'>
                  <p className='text--XS'>Competitors</p>
                  <Checkbox
                    checked
                    className="short"
                    disabled={competitorsExistance}
                    onChange={(e) => setCompetitorsExistance(e.target.checked)}
                  />
                  <MultipleDropdown
                    options={advancedDropdownOptions}
                    value={competitorsReliability}
                    onChange={(e) => setCompetitorsReliability(e.target.value)}
                  />
                  <MultipleDropdown
                    options={competitorsSoundnessDropdownOptions}
                    value={competitorsSoundness}
                    onChange={(e) => setCompetitorsSoundness(e.target.value)}
                  />
                </div>
                <div className='dropdown'>
                  <p className='text--XS'>TRL</p>
                  <Checkbox
                    checked
                    className="short"
                    disabled={trlExistance}
                    onChange={(e) => setTrlExistance(e.target.checked)}
                  />
                  <MultipleDropdown
                    options={advancedDropdownOptions}
                    value={trlReliability}
                    onChange={(e) => setTrlReliability(e.target.value)}
                  />
                  <MultipleDropdown
                    options={tlrSoundnessDropdownOptions}
                    value={trlSoundness}
                    onChange={(e) => setTrlSoundness(e.target.value)}
                  />
                </div>
                <div className='dropdown'>
                  <p className='text--XS'>Innovative Risk</p>
                  <Checkbox
                    checked
                    className="short"
                    disabled={innovativeRiskExistance}
                    onChange={(e) => setInnovativeRiskExistance(e.target.checked)}
                  />
                  <MultipleDropdown
                    options={advancedDropdownOptions}
                    value={innovativeRiskReliability}
                    onChange={(e) => setInnovativeRiskReliability(e.target.value)}
                  />
                  <MultipleDropdown
                    options={innovativeRiskSoundnessDropdownOptions}
                    value={innovativeRiskSoundness}
                    onChange={(e) => setInnovativeRiskSoundness(e.target.value)}
                  />
                </div>
                <div className='dropdown'>
                  <p className='text--XS'>ESG issues</p>
                  <Checkbox
                    checked
                    className="short"
                    disabled={esgExistance}
                    onChange={(e) => setEsgExistance(e.target.checked)}
                  />
                  <MultipleDropdown
                    options={advancedDropdownOptions}
                    value={esgReliability}
                    onChange={(e) => setEsgReliability(e.target.value)}
                  />
                  <MultipleDropdown
                    options={esgSoundnessDropdownOptions}
                    value={esgSoundness}
                    onChange={(e) => setEsgSoundness(e.target.value)}
                  />
                </div>
                <div className='dropdown'>
                  <p className='text--XS'>IP ownership</p>
                  <Checkbox
                    checked
                    className="short"
                    disabled={ipOwnershipExistance}
                    onChange={(e) => setIpOwnershipExistance(e.target.checked)}
                  />
                  <MultipleDropdown
                    options={advancedDropdownOptions}
                    value={ipOwnershipReliability}
                    onChange={(e) => setIpOwnershipReliability(e.target.value)}
                  />
                  <MultipleDropdown
                    options={ipOwnershipSoundnessDropdownOptions}
                    value={ipOwnershipSoundness}
                    onChange={(e) => setIpOwnershipSoundness(e.target.value)}
                  />
                </div>
                <div className='dropdown'>
                  <p className='text--XS'>International coverage</p>
                  <Checkbox
                    checked
                    className="short"
                    disabled={internationalCoverageExistance}
                    onChange={(e) => setInternationalCoverageExistance(e.target.checked)}
                  />
                  <MultipleDropdown
                    options={advancedDropdownOptions}
                    value={internationalCoverageReliability}
                    onChange={(e) => setInternationalCoverageReliability(e.target.value)}
                  />
                  <MultipleDropdown
                    options={internationalCoverageSoundnessDropdownOptions}
                    value={internationalCoverageSoundness}
                    onChange={(e) => setInternationalCoverageSoundness(e.target.value)}
                  />
                </div>
                <div className='dropdown'>
                  <p className='text--XS'>Consistency of assumptions</p>
                  <Checkbox
                    checked
                    className="short"
                    disabled={consistencyOfAssumptionsExistance}
                    onChange={(e) => setConsistencyOfAssumptionsExistance(e.target.checked)}
                  />
                  <MultipleDropdown
                    options={advancedDropdownOptions}
                    value={consistencyOfAssumptionsReliability}
                    onChange={(e) => setConsistencyOfAssumptionsReliability(e.target.value)}
                  />
                  <MultipleDropdown
                    options={consistencyOfAssumptionsSoundnessDropdownOptions}
                    value={consistencyOfAssumptionsSoundness}
                    onChange={(e) => setConsistencyOfAssumptionsSoundness(e.target.value)}
                  />
                </div>
              </div>
              <div className='button-area'>
                <button className='text--button button-primary'>Continue</button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
      <div className='survey-form'>
        <form onSubmit={handleSubmit}>
          <fieldset id='wow-rating' className='wow-rating' disabled={disableWowRating}>
            <h3 className='text--XL'>wow rating</h3>
            <p className='text--S'>Insert a votation for your WOW judgment from 1 to 10 (10 the best).<br />
              The WOW effect is the alignment of the project with your values, your expectations and your comprehensive vision of the future.
            </p>
            <Dropdown
              options={numberDropdown}
              value={wowRating}
              onChange={(e) => setWowRating(e.target.value)}
            />
            <div className='button-area'>
              <button className='text--button button-primary'>Continue</button>
            </div>
            {errorForm && <div className="error">{errorForm}</div>}
          </fieldset>
        </form>
      </div>
      <div id='results' className={blurredArea ? 'container blur' : 'container'}>
        <div className='color-line'>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="rating-container">
          <h2 className='text--L'>The results of your assessment</h2>
          <div className='rating-container__values'>
            <div className='rating-element'>
              <h3>Score</h3>
              <p className='text--XL rating-box'>{score}</p>
            </div>
            <div className='rating-element'>
              <h3>Grade</h3>
              <p className='text--XL rating-box'>{grade}</p>
            </div>
            <div className='rating-element' >
              <h3>Wow rating</h3>
              <p className='text--XL rating-box'>{wowRate}</p>
            </div>
          </div>
          <p className='text--XS'>These forms allow to perform the qualitative assessment of a startup, a project or a business plan based on Suite23 architecture. The assessment is powerful, collaborative, time saving, suitable for massive or single evaluation.</p>
          <h3 className='text--M'>Name your assessment and save it for the future</h3>
          <form onSubmit={(e) => updateSurvey(e, {
            name: name ? name : '',
            email: email ? email : 'guest user'
          })}>
            <input type='text' placeholder='Insert here the name' onChange={(e) => setName(e.target.value)}></input>
            <div className='button-area'>
              <button className='text--button button-primary'>Save</button>
            </div>
            {success && <div className="text--success success"><p>The assessment has been saved correctly</p><Link to='account' className='bold'>You can review it here</Link></div>}
            {errorForm && <div className="error">{errorForm}</div>}
          </form>
        </div>
      </div>
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div className="modal">
          <UserPopup onSuccess={closeModalAndSubmit} />
        </div>
      </Popup>
    </>
  )
}


export default SurveyForm