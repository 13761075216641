import { createContext, useReducer } from 'react'

export const SurveyContext = createContext()

export const surveyReducer = (state, action) => {
    switch (action.type) {
        case 'SURVEYS': 
            return {
                surveys: action.payload
            }
        case 'CREATE_SURVEYS':
            return {
                surveys: [action.payload, ...state.surveys]
            }
        case 'DELETE_SURVEYS': 
            return {
                surveys: state.surveys.filter((s) => s._id !== action.payload._id)
            }
        default: 
            return state
    }
}

export const SurveyContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(surveyReducer, {
        surveys: null
    })


    return (
        <SurveyContext.Provider value={{...state, dispatch}}>
            { children }
        </SurveyContext.Provider>
    )
}