import { useAuthContext } from "../hooks/useAuthContext"

// components
import SurveyForm from "../components/SurveyForm"
import Hero from '../components/Hero'
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"

const Home = () => {

    //const { user } = useAuthContext()


    return (
        <div >
            <Navbar />
            <Hero />
            <SurveyForm />
            <Footer />
        </div>
    )
}

export default Home