import { useState } from "react";

const EditIcon = () => {
    return (
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.89645 0.146447C9.09171 -0.0488155 9.40829 -0.0488155 9.60355 0.146447L12.3536 2.89645C12.5488 3.09171 12.5488 3.40829 12.3536 3.60355L4.10355 11.8536C4.00979 11.9473 3.88261 12 3.75 12H1C0.723858 12 0.5 11.7761 0.5 11.5V8.75C0.5 8.61739 0.552678 8.49022 0.646447 8.39645L8.89645 0.146447ZM1.5 8.95711V11H3.54289L11.2929 3.25L9.25 1.20711L1.5 8.95711Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.14645 2.89645C6.34171 2.70118 6.65829 2.70118 6.85355 2.89645L9.60355 5.64645C9.79882 5.84171 9.79882 6.15829 9.60355 6.35355C9.40829 6.54882 9.09171 6.54882 8.89645 6.35355L6.14645 3.60355C5.95118 3.40829 5.95118 3.09171 6.14645 2.89645Z" fill="white" />
        </svg>


    );
};
export default EditIcon;