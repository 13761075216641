const Dropdown = ({ label, value, options, onChange }) => {
    return (
        <label className="single-dropdown">
            <div className='label-dropdown'>
                {label}
            </div>
            <div className="select-dropdown">
                <select value={value} onChange={onChange}>
                    {options.map((option) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </select>
            </div>
        </label>
    );
};

export default Dropdown