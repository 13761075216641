import { useState } from "react";

const DashboardLogo = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10 0C10.5523 0 11 0.447715 11 1V9.60902L16.7348 15.8217C17.1094 16.2275 17.0841 16.8602 16.6783 17.2348C16.2725 17.6094 15.6398 17.5841 15.2652 17.1783L9.2652 10.6783C9.09468 10.4936 9 10.2514 9 10V1C9 0.447715 9.44772 0 10 0Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9 10C9 9.44772 9.44772 9 10 9H19C19.5523 9 20 9.44772 20 10C20 10.5523 19.5523 11 19 11H10C9.44772 11 9 10.5523 9 10Z" fill="white" />
        </svg>
    );
};
export default DashboardLogo;