import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'

// import pages and components
import Home from './pages/Home'
import PersonalArea from './pages/PersonalArea'
import Login from './pages/Login'
import Signup from './pages/Signup'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import NavbarAdmin from './components/NavbarAdmin'

function App() {
  const { user } = useAuthContext()

  function BasicLayout() {
    return (
      <>
        <Navbar />
        <Outlet />
        <Footer />
      </>
    )
  }

  function AdminLayout() {
    return (
      <>
        <NavbarAdmin />
        <Outlet />
        <Footer />
      </>
    )
  }

  function RegistrationLayout() {
    return (
      <>
        <Outlet />
      </>
    )
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/account" element={<AdminLayout />} >
            <Route index element={user ? <PersonalArea /> : <Navigate to="/login" />} />
          </Route>
          <Route path="/login" element={<RegistrationLayout />} >
            <Route index element={!user ? <Login /> : <Navigate to="/account" />} />
          </Route>
          <Route path="/signup" element={<RegistrationLayout />} >
            <Route index element={!user ? <Signup /> : <Navigate to="/account" />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>

    /*<div className="App">
      <BrowserRouter>
        <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/account" element={user ? <PersonalArea /> : <Navigate to="/login" />} />
            <Route path="/login" element={!user ? <Login /> : <Navigate to="/account" />} />
            <Route path="/signup" element={!user ? <Signup /> : <Navigate to="/account" />} />
          </Routes>
          <Footer />
      </BrowserRouter>
    </div>*/
  );
}

export default App;
