import { useState } from "react";
import { useLogin } from "../hooks/useLogin";
import LogoLogin from "../assets/svg/suite-23-login.svg"
import SidebarRegistration from "../components/SidebarRegistration";
import GoBack from "../components/GoBack";
import { Link } from "react-router-dom";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')
    const { login, errorLogin, isLoadingLogin } = useLogin()

    const handleSubmit = async (e) => {
        e.preventDefault()

        await login(email, password)
    }

    return (
        <div className="registration-form">
            <SidebarRegistration />
            <div className="form">
                <GoBack text={"Go to homepage"} link={"/"} position='top' />
                <div className="form-title">
                    <h2 className="text--M">Benvenuto!</h2>
                    <p className="text--XS">Inserisci le tue credenziali<br /> per accedere</p>
                </div>
                <form className="user-form" onSubmit={handleSubmit}>
                    <input
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        placeholder="Email"
                    />
                    <input
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        placeholder="Password"
                    />
                    {errorLogin && <div className="text--alert error">{errorLogin}</div>}
                    <button className='bold' disabled={isLoadingLogin}>Log in</button>

                </form>
                <p><span>Need an account?</span><Link className="bold" to='/signup'>Signup</Link></p>
            </div>
        </div>

    )
}

export default Login