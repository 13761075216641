import { Link } from "react-router-dom"
import logo from '../assets/svg/suite23nero.svg'

const Test = () => {

    return (
        <footer>
            <div className='logo'>
                <Link to="/">
                    <img src={logo} alt='suite23' />
                </Link>
            </div>
            <div className="info">
                <p>Suite 23 Srl</p>
                <p>Via Pauliana 2</p>
                <p>34132 Trieste - Italy</p>
                <p>P.IVA 01331300325</p>
                <a href='mailto:amministrazione@suite-23.com'>amministrazione@suite-23.com</a>
            </div>
            <div>
                <Link to="https://www.suite-23.com/policy/">Cookie Policy</Link>
            </div>
        </footer >
    )
}

export default Test