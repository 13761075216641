import { useState } from "react"
import { useSignup } from "../hooks/useSignup"
import LogoLogin from "../assets/svg/suite-23-login.svg"
import SidebarRegistration from "../components/SidebarRegistration"
import GoBack from "../components/GoBack"
import { Link } from "react-router-dom"


const Signup = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [company, setCompany] = useState('')
  const [vatCode, setVatCode] = useState('')
  const { signup, errorSignup, isLoadingSignup } = useSignup()

  const handleSubmit = async (e) => {
    e.preventDefault()

    await signup(firstName, lastName, email, password, confirmPassword, company, vatCode)
  }

  return (<div className="registration-form">
    <SidebarRegistration />
    <div className="form">
      <GoBack text={"Go to homepage"} link={"/"} position='top'/>
      <div className="form-title">
        <h2 className="text--M">Benvenuto!</h2>
        <p className="text--XS">Inserisci le tue credenziali<br /> per accedere</p>
      </div>
      <form className="user-form" onSubmit={handleSubmit}>
        <input
          type="text"
          onChange={(e) => setFirstName(e.target.value)}
          value={firstName}
          placeholder="First name"
          required
        />
        <input
          type="text"
          onChange={(e) => setLastName(e.target.value)}
          value={lastName}
          placeholder="Last name"
          required
        />
        <input
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          placeholder="Password"
          required
        />
        <input
          type="password"
          onChange={(e) => setConfirmPassword(e.target.value)}
          value={confirmPassword}
          placeholder="Confirm password"
          required
        />
        <input
          type="text"
          onChange={(e) => setCompany(e.target.value)}
          value={company}
          placeholder="Company"
          required
        />
        <input
          type="text"
          onChange={(e) => setVatCode(e.target.value)}
          value={vatCode}
          placeholder="VAT code"
          required
        />
        <input
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          placeholder="Email"
          required
        />
        {errorSignup && <div className="text--alert error">{errorSignup}</div>}
        <button className='bold' disabled={isLoadingSignup}>Sign up</button>
        
      </form>
      <p><span>Already have an account?</span><Link className="bold" to='/login'>Login</Link></p>
    </div>
  </div>

  )
}

export default Signup