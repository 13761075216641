import backgroundImage from '../assets/img/bg-intro.png'

const Hero = () => {

    return (
        <>
            <div className='hero-container'>
                <div className="hero">
                    <h1 className='text--XL'>Multi<br />criteria<br />tool for<br />startup<br />evaluation</h1>
                    <div className='text-button'>
                        <p className='text--XS'>These forms allow to perform the qualitative assessment of a startup, a project or a business plan based on Suite23 architecture. The assessment is powerful, collaborative, time saving, suitable for massive or single evaluation.</p>
                        <a className='text--button button button-secondary' href='/signup'>Sign up</a>
                    </div>
                    <img src={backgroundImage} alt='suite23 chip' />
                </div>
                <div className='color-line'>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </>
    )
}

export default Hero