import { useState } from "react";

const LogoutIcon = () => {
    return (
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.580078 7.50004C0.580078 7.10792 0.897956 6.79004 1.29008 6.79004L11.2901 6.79004C11.6822 6.79004 12.0001 7.10792 12.0001 7.50004C12.0001 7.89216 11.6822 8.21004 11.2901 8.21004L1.29008 8.21004C0.897956 8.21004 0.580078 7.89216 0.580078 7.50004Z" fill="#1A202C" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5.79212 2.99799C6.0694 3.27527 6.0694 3.72481 5.79212 4.00208L2.29417 7.50004L5.79212 10.998C6.0694 11.2753 6.0694 11.7248 5.79212 12.0021C5.51485 12.2794 5.0653 12.2794 4.78803 12.0021L0.788032 8.00208C0.51076 7.72481 0.51076 7.27526 0.788032 6.99799L4.78803 2.99799C5.0653 2.72072 5.51485 2.72072 5.79212 2.99799Z" fill="#1A202C" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.58008 1.50004C8.58008 1.10792 8.89796 0.790039 9.29008 0.790039H18.0001V15.21H9.29008C8.89796 15.21 8.58008 14.8922 8.58008 14.5C8.58008 14.1079 8.89796 13.79 9.29008 13.79H16.5801V2.21004H9.29008C8.89796 2.21004 8.58008 1.89216 8.58008 1.50004Z" fill="#1A202C" />
        </svg>
    );
};
export default LogoutIcon;