import { useEffect, useRef, useState } from "react";
import { useLogin } from "../hooks/useLogin";
import { useSignup } from "../hooks/useSignup";
import LogoLogin from "../assets/svg/suite-23-login.svg"
import SidebarRegistration from "../components/SidebarRegistration";
import GoBack from "../components/GoBack";
import { Link } from "react-router-dom";
import { setRef } from "@mui/material";
import { useNonInitialEffect } from "../hooks/useNonInitialEffect";
import { useAuthContext } from "../hooks/useAuthContext";

const UserPopup = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')
    const { login, errorLogin, isLoadingLogin } = useLogin()
    const { user } = useAuthContext()
    const [emailSignup, setEmailSignup] = useState('')
    const [passwordSignup, setPasswordSignup] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [company, setCompany] = useState('')
    const [vatCode, setVatCode] = useState('')
    const { signup, errorSignup, isLoadingSignup } = useSignup()
    const [showLogin, setShowLogin] = useState(true)

    const handleSubmitSignup = async (e) => {
        e.preventDefault()

        await signup(firstName, lastName, emailSignup, passwordSignup, confirmPassword, company, vatCode)
    }

    const handleSubmitLogin = async (e) => {
        e.preventDefault()
        await login(email, password)
    }

    useEffect(() => {
        if (errorLogin === null && user) {
            console.log("entrato on success")
            props.onSuccess()
        }
    }, [errorLogin, user])

    useEffect(() => {
        if (errorSignup === null && user) {
            console.log("entrato on success")
            props.onSuccess()
        }
    }, [errorSignup, user])

    return (
        <>
            {showLogin ? 
                <div id='login' className="registration-form">
                    <div className="form">
                        <div className="form-title">
                            <h2 className="text--M">Welcome back!</h2>
                            <p className="text--XS">Insert your credentials<br /> to log in</p>
                        </div>
                        <form className="user-form" onSubmit={handleSubmitLogin}>
                            <input
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                placeholder="Email"
                            />
                            <input
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                placeholder="Password"
                            />
                            {errorLogin && <div className="text--alert error">{errorLogin}</div>}
                            <button className='bold' disabled={isLoadingLogin}>Log in</button>

                        </form>
                        <p><span>Need an account?</span><span className="bold pointer" onClick={() => { setShowLogin(false) }}>Signup</span></p>
                    </div>
                </div>
                :
                <div id='signup' className="registration-form">
                    <div className="form">
                        <div className="form-title">
                            <h2 className="text--M">You are welcome!</h2>
                            <span className="text--XS">Insert your credentials<br /> to sign up</span>
                        </div>
                        <form className="user-form" onSubmit={handleSubmitSignup}>
                            <input
                                type="text"
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                                placeholder="First name"
                                required
                            />
                            <input
                                type="text"
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                                placeholder="Last name"
                                required
                            />
                            <input
                                type="password"
                                onChange={(e) => setPasswordSignup(e.target.value)}
                                value={passwordSignup}
                                placeholder="Password"
                                required
                            />
                            <input
                                type="password"
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                value={confirmPassword}
                                placeholder="Confirm password"
                                required
                            />
                            <input
                                type="text"
                                onChange={(e) => setCompany(e.target.value)}
                                value={company}
                                placeholder="Company"
                                required
                            />
                            <input
                                type="text"
                                onChange={(e) => setVatCode(e.target.value)}
                                value={vatCode}
                                placeholder="VAT code"
                                required
                            />
                            <input
                                type="email"
                                onChange={(e) => setEmailSignup(e.target.value)}
                                value={emailSignup}
                                placeholder="Email"
                                required
                            />
                            {errorSignup && <div className="text--alert error">{errorSignup}</div>}
                            <button className='bold' disabled={isLoadingSignup}>Sign up</button>

                        </form>
                        <p><span>Already have an account?</span><span className="bold pointer" onClick={() => setShowLogin(true)}>Login</span></p>
                    </div>
                </div>}
        </>
    )
}

export default UserPopup