import formatDisanceToNow from 'date-fns/formatDistanceToNow'
import { useAuthContext } from '../hooks/useAuthContext'
import moment from 'moment'
import React, { useState, useRef, useEffect } from "react";


//Media
import EditIcon from './svg/EditIcon'
import SurveyDetails from './SurveyDetails';
import { parseWithOptions } from 'date-fns/fp';


const SurveyTitle = ({ survey, selected, index, mobile, onUpdate }) => {

    const { user } = useAuthContext()

    const inputRef = useRef(null);
    const [inputVisible, setInputVisible] = useState(false);
    const [name, setName] = useState(survey.name);

    const updateName = () => {
        fetch(`/api/surveys/${survey._id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                name
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user ? user.token : ''}`
            }
        })
            .then(res => {
                if (res.ok) {
                    onUpdate()
                    return res.json()
                }
            })
            .catch((errorForm) => {
                console.log(errorForm)
            })
    }

    function onClickOutSide(e) {
        // Check if user is clicking outside of <input>
        if (inputRef.current && !inputRef.current.contains(e.target)) {
            setInputVisible(false); // Disable text input
            updateName();
        }
    }

    function onEnterClick(e) {
        setInputVisible(false);
        updateName();
    }

    useEffect(() => {
        // Handle outside clicks on mounted state
        if (inputVisible) {
            document.addEventListener("mousedown", onClickOutSide);
        }

        // This is a necessary step to "dismount" unnecessary events when we destroy the component
        return () => {
            document.removeEventListener("mousedown", onClickOutSide);
        };
    });

    return (
        <>
            <div className={"survey-element " + (selected === index ? ' selected ' : '') + (mobile && index === selected ? " hidden" : '')} id={'survey-element-' + index}>
                <div className='title-icons'>
                    {inputVisible ? (
                        <input
                            ref={inputRef}
                            value={name} // Now input value uses local state
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                        />
                    ) : (
                        <h4 className="bold text--S " onClick={() => setInputVisible(true)}>{name ? name : 'Unnamed'}</h4>
                    )}


                    <div className="svg-circle" onClick={(e) => { e.preventDefault(); inputVisible ? setInputVisible(false) : setInputVisible(true) }} >
                        <EditIcon />
                    </div>
                </div>
                <p><span className="text--XS bold">Date:</span><span className='text--XS'>{moment(survey.createdAt).format('DD-MM-YYYY HH:mm')}</span></p>
            </div>
            <div id={'detail-mobile-' + index}>
            { mobile && index===selected ? 
            
                <SurveyDetails survey={survey}/>
            
            : <></>}
            </div>
        </>
    )
}

export default SurveyTitle