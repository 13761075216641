import { useAuthContext } from "../hooks/useAuthContext"
import { useState, useEffect, useLayoutEffect } from "react"
import { Link } from "react-router-dom"


// components
import SurveyTitle from '../components/SurveyTitle'
import SurveyDetails from '../components/SurveyDetails'
import GoBack from "../components/GoBack"
import SearchBar from "../components/SearchBar"

// media
import DashboardLogo from '../components/svg/DashboardIcon'


const PersonalArea = () => {

    const { user } = useAuthContext()
    const [surveys, setSurveys] = useState([])
    const [surveyToShow, setSurveyToShow] = useState(null)
    const [selected, setSelected] = useState(0)
    const [mobile, setMobile] = useState(null)

    useEffect(() => {        
        fetchSurveys();
    }, [])

    const fetchSurveys = async () => {
        const response = await fetch('/api/surveys', {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()

        if (window.innerWidth < 801) {
            console.log('aaa')
            setMobile(true)
        }
        setSurveys(json)
        setSurveyToShow(json[0])
        window.addEventListener('resize', handleResize)
    }

    function handleResize() {
        if (window.innerWidth > 801) {
            setMobile(false)
        } else {
            setMobile(true)
        }
    }

    /*useLayoutEffect(() => {
        if (window.innerWidth < 801) {
            document.getElementById('survey-element-0').style.display = 'none'
            const details = document.getElementById('survey-detail')
            const area = document.getElementById('detail-mobile-0')
            area.appendChild(details)
            details.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
        }
    }, [surveyToShow]);*/


    /*
    if (window.innerWidth < 801) {
            document.getElementById('survey-element-0').style.display = 'none'
            const details = document.getElementById('survey-detail')
            const area = document.getElementById('detail-mobile-0')
            area.appendChild(details)
            details.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
        }
    */


    const showSurveyDetails = (e, survey, index) => {
        e.preventDefault()
        if (window.innerWidth < 801) {
            for (let element of document.getElementsByClassName("survey-element")) {
                element.classList.remove("hidden");
            }
            const details = document.getElementById('survey-detail')
            details.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
            /*document.getElementById('survey-element-' + index).classList.add("hidden");
            
            const area = document.getElementById('detail-mobile-' + index)
            area.appendChild(details)
            details.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })*/
        }
        setSurveyToShow(survey)
        setSelected(index)
    }


    const surveyResults = (surveys) => {
        setSurveys(surveys)
        setSurveyToShow(surveys[0])
    }

    return (
        <div className="admin-panel">
            <div className="admin-controls">
                <div className='control selected'>
                    <DashboardLogo />
                    <p className="text--S bold">Dashboard</p>
                </div>
                <GoBack text={"Go to homepage"} link={"/"} position='bottom' />
            </div>
                <div className="survey-area">
                    <div className="survey">

                        <SearchBar onSuccess={surveyResults} />
                        {surveys.length > 0 ?
                            surveys && surveys.map((survey, index) => (
                                <Link onClick={(e) => showSurveyDetails(e, survey, index)} key={index} to={'?id=' + survey._id}>
                                    <SurveyTitle key={survey._id} survey={survey} index={index} selected={selected} mobile={mobile} onUpdate={fetchSurveys}/>
                                </Link>

                            )) : <p className="text--S">There isn't any assessment yet. <Link className='bold' to='/'>Try one</Link></p>}
                    </div>
                    {surveyToShow ?
                        <div id='survey-detail' className="survey-details">
                            <SurveyDetails survey={surveyToShow} />
                        </div>
                        : <></>}
                </div>
            </div>
    )
}

export default PersonalArea