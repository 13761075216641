import GoBackIcon from '../assets/svg/goback.svg'
import { Link } from "react-router-dom"

const GoBack = (props) => {

    return (
        <Link className={'go-back__' + props.position} to={props.link}>
            <img src={GoBackIcon} alt='go back' />
            <span className='bold text--S'>{props.text}</span>
        </Link>
    )
}

export default GoBack