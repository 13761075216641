import LogoLogin from '../assets/svg/suite-23-login.svg'

const SidebarRegistration = () => {

    return (
        <div className="brand-sidebar">
            <img src={LogoLogin} alt='Suite 23' />
            <h1 className='text--XL'>Multi<br /> criteria <br />tool for<br /> startup <br />evaluation</h1>
            <div className="brand-dots">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default SidebarRegistration