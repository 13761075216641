import { useState } from "react";

const AreaPersonaleIcon = () => {
    return (
        <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.86848 12.4713C1.96235 13.0377 1.54167 13.7293 1.54167 14.375C1.54167 14.7662 1.22453 15.0833 0.833333 15.0833C0.442132 15.0833 0.125 14.7662 0.125 14.375C0.125 13.0646 0.972842 11.9855 2.11765 11.27C3.27243 10.5483 4.82368 10.125 6.5 10.125C8.17632 10.125 9.72757 10.5483 10.8824 11.27C12.0272 11.9855 12.875 13.0646 12.875 14.375C12.875 14.7662 12.5579 15.0833 12.1667 15.0833C11.7755 15.0833 11.4583 14.7662 11.4583 14.375C11.4583 13.7293 11.0376 13.0377 10.1315 12.4713C9.23538 11.9112 7.95329 11.5417 6.5 11.5417C5.04671 11.5417 3.76462 11.9112 2.86848 12.4713Z" fill="#1A202C" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.5 2.33317C4.93519 2.33317 3.66667 3.6017 3.66667 5.1665C3.66667 6.73131 4.93519 7.99984 6.5 7.99984C8.06481 7.99984 9.33333 6.73131 9.33333 5.1665C9.33333 3.6017 8.06481 2.33317 6.5 2.33317ZM2.25 5.1665C2.25 2.81929 4.15279 0.916504 6.5 0.916504C8.84721 0.916504 10.75 2.81929 10.75 5.1665C10.75 7.51371 8.84721 9.4165 6.5 9.4165C4.15279 9.4165 2.25 7.51371 2.25 5.1665Z" fill="#1A202C" />
        </svg>

    );
};
export default AreaPersonaleIcon;